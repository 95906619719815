import React, { useEffect, useRef } from "react";

import { useLocales, useMapLegend } from "hooks";
import { MapLegend, MapKind, MapNavigator, Canton } from "components";

interface ImageSources {
  small: string;
  large: string;
}
export interface FireHomeTabPanelProps {
  imageSources: ImageSources;
  id: string;
  initialActive?: boolean;
  imageAlt: string;
  link: string;
  type: MapKind;
  legendLink: string;
  cantons: ReadonlyArray<Canton>;
  regionsJsonPath: string;
  measuresJsonPath?: string;
}

export const TabPanel = ({
  imageSources,
  id,
  imageAlt,
  link,
  type,
  legendLink,
  cantons,
  regionsJsonPath,
  measuresJsonPath,
  initialActive = false,
}: FireHomeTabPanelProps): JSX.Element => {
  const { t } = useLocales();
  const legendRef = useRef(null);
  const triggerRef = useRef(null);
  const [shown, toggleShown] = useMapLegend({ triggerRef, legendRef });

  useEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip();
  }, []);

  return (
    <div className={`tab-pane ${initialActive ? "active" : ""}`} id={id}>
      <div className="container-fluid map-tab-panel">
        <div className="map">
          <div className="map-image">
            <img
              src={imageSources.small}
              srcSet={`${imageSources.small} 1028w, ${imageSources.large} 2384w`}
              alt={imageAlt}
            />
            <div className="map-controls map-control-tabs">
              <a
                className="btn btn-default map-control"
                href={link}
                aria-label={t("interactive_map_link_tooltip")}
                data-toggle="tooltip"
                data-placement="left"
                title={t("interactive_map_link_tooltip")}
              >
                <span className={`glyphicon glyphicon-eye-open`} />
              </a>
              <button
                ref={triggerRef}
                type="button"
                className="btn btn-default map-control"
                onClick={toggleShown}
                aria-label={t("info")}
                value="info"
              >
                <span className={`icon icon--${shown ? "close" : "list"}`} />
              </button>
            </div>
            <MapLegend
              type={type}
              shown={shown}
              ref={legendRef}
              link={legendLink}
            />
          </div>
          <div className="map-navigator">
            <MapNavigator
              type={type}
              cantons={cantons}
              regionsJsonPath={regionsJsonPath}
              measuresJsonPath={measuresJsonPath}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
