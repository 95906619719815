import React from "react";
import { useLocales } from "hooks";
import { TabPanel } from "./TabPanel";
import { Canton } from "components";

interface ImageSources {
  small: string;
  large: string;
}
interface Props {
  warnMapSources: ImageSources;
  measuresMapSources: ImageSources;
  cantons: ReadonlyArray<Canton>;
  warnMapJsonPath: string;
  measuresMapRegionsJsonPath: string;
  measuresMapJsonPath: string;
  warnMapLink: string;
  measuresMapLink: string;
  warnMapLegendLink: string;
  measuresMapLegendLink: string;
}

export const FireMapTabs: React.FC<Props> = ({
  warnMapSources,
  measuresMapSources,
  cantons,
  warnMapJsonPath,
  measuresMapRegionsJsonPath,
  measuresMapJsonPath,
  measuresMapLink,
  warnMapLink,
  warnMapLegendLink,
  measuresMapLegendLink,
}) => {
  const { t } = useLocales();
  return (
    <React.Fragment>
      <ul className="nav nav-tabs">
        <li className="active">
          <a href="#forest-fire" data-toggle="tab">
            {t("tabs.fire")}
          </a>
        </li>
        <li>
          <a href="#measures" data-toggle="tab">
            {t("tabs.measures")}
          </a>
        </li>
      </ul>

      <div className="tab-content tab-border">
        <TabPanel
          imageSources={warnMapSources}
          imageAlt={t("images.alt.fire")}
          id="forest-fire"
          initialActive
          link={warnMapLink}
          type="warnMap"
          legendLink={warnMapLegendLink}
          cantons={cantons}
          regionsJsonPath={warnMapJsonPath}
        />
        <TabPanel
          imageSources={measuresMapSources}
          imageAlt={t("images.alt.measures")}
          id="measures"
          link={measuresMapLink}
          type="measures"
          legendLink={measuresMapLegendLink}
          cantons={cantons}
          regionsJsonPath={measuresMapRegionsJsonPath}
          measuresJsonPath={measuresMapJsonPath}
        />
      </div>
    </React.Fragment>
  );
};
